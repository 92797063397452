import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/widgets/layout"
import Seo from "../../../components/seo"

const JimdoPage = () => {
    return (
        <Layout>
            <Seo
                seo={{
                    metaTitle: "Integrate the rentware widgets into your Jimdo project",
                    metaDescription: "It looks like you got to the widgets integration section",
                }}
            />
            <main className="container p-6 mx-auto md:px-12">
                <div className="prose max-w-none">
                    <h1>Jimdo</h1>
                    <h2>Rentware widgets for Jimdo websites</h2>
                    <div>In this example we integrate the <Link to="/widgets/demo/search">search widget</Link></div>
                    <h3>Step 1:</h3>
                    <h4>Settings | Edit Head</h4>
                    <pre title="JavaScript">
                        <code>{`<script type='module' src='https://cdn.rtr-io.com/widgets.js'></script>

<script type='text/javascript'>
//<![CDATA[
    window.RTR_ACCESS_TOKEN = 'YOUR_ACCESS_TOKEN';
    window.RENTWARE_LANGUAGE = "de-DE";
    document.onreadystatechange = () => {
    if (document.readyState === "complete") {
        //SEARCH WIDGET
        const searchContainer = document.getElementById("search-container");
        if (searchContainer) {
        const el = document.createElement("rtr-search");
        if (searchContainer.dataset.rwlocationid) {
            el.setAttribute("locations", searchContainer.dataset.rwlocationid);
        }

        searchContainer.append(el);
        }

        //ARTICLE BOOKING WIDGET
        const articleContainers = document.querySelectorAll("[data-typ='rentware-article-container']");
        if (articleContainers.length > 0) {
        articleContainers.forEach(function (article) {
            const rwId = article.getAttribute("data-rentware-article-id");
            const el = document.createElement("rtr-article-booking");
            el.setAttribute("article-id", rwId);
            article.append(el);
        });
        }

        // CHECKOUT
        const checkoutEl = document.createElement("rtr-checkout");
        document.body.appendChild(checkoutEl);
    }
    };
//]]>
</script>
`}</code></pre>
                    <h3>Step 2:</h3>
                    <h4>Add element | More elements and Add-ons | Widget / HTML</h4>
                    <div>Create a widget/custom HTML container and paste the following HTML</div>
                    <pre title="HTML">
                        <code>{`<div id="search-container" style="min-height: 200px"></div>
`}</code></pre>
                    <pre title="HTML">
                        <code>{`<div data-typ="rentware-article-container" data-rentware-article-id="XYZ"></div>
`}</code></pre>
                </div>
            </main>

        </Layout>
    );
}

export default JimdoPage;